<template>
  <div>
    <Row>
      <i-col span="24">
        <Select v-model="chooseCompanyId" @on-change="handleChangeCompanyId">
            <Option v-for="item in childCompanyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="p-t-5 p-b-10">
      <i-col span="24">
        <Select v-model="chooseAssetId" @on-change="handleChangeAssetId">
            <Option v-for="item in companyAssetList" :value="item.keyId" :key="item.keyId">{{ item.content }}</Option>
        </Select>
      </i-col>
    </Row>

    <Table class="stationTable" size="small" :columns="stationColumn" :data="stationData" :row-class-name="rowClassName"
      @on-row-click="handleClickRow"></Table>

    <div v-show="showArray.length>currentPageSize" class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { listChildrenCompanyAndSelf, listCompanyAssetsAndAll } from '@/api/dw/datav'
import { computeStationValue } from '@/api/dw/station'

export default {
  data () {
    return {
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,

      chooseCompanyId: '0',
      chooseAssetId: '0',
      chooseTableId: null,

      childCompanyList: [],
      companyAssetList: [],

      stationColumn: [
        { title: '站点名称', width: 80, key: 'name', sortable: true },
        {
          title: '去化率',
          align: 'right',
          width: 70,
          key: 'actualAmount',
          render: (h, params) => {
            return h('span', params.row.actualAmount + '%')
          },
          sortable: true
        },
        {
          title: '执行额(占比)',
          align: 'right',
          width: 120,
          key: 'executeAmount',
          render: (h, params) => {
            return h('span', toMoney(params.row.executeAmount) + '(' + params.row.executeRate + '%)')
          },
          sortable: true
        },
        { title: '价值', align: 'right', key: 'rate', sortable: true }
      ],
      stationData: [],

      showArray: [],
      currentPageSize: 25,
      currentPageNumber: 1
    }
  },
  created () {
    this.loadCompanyList()
  },
  methods: {
    loadCompanyList () {
      const postData = {
        endDate: this.searchSchedule.endDate,
        loginCompanyId: this.loginCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate
      }

      listChildrenCompanyAndSelf(postData).then(res => {
        if (res.length > 0) {
          this.chooseCompanyId = res[0].id
          this.loadCompanyAssetList()
        }
        this.childCompanyList = res
      })
    },
    loadCompanyAssetList () {
      const queryModel = {
        companyId: this.chooseCompanyId,
        publisherId: this.publisherId
      }

      listCompanyAssetsAndAll(queryModel).then(res => {
        this.companyAssetList = res
        if (res.length > 0) {
          this.chooseAssetId = res[0].keyId
          this.handleChangeAssetId()
        }
      })
    },
    handleChangeCompanyId () {
      this.$store.commit('set_situation_companyId', this.chooseCompanyId)
      this.chooseAssetId = '0'
      this.loadCompanyAssetList()

      // this.handleChangeAssetId()
    },
    handleChangeAssetId () {
      this.$store.commit('set_situation_assetId', this.chooseAssetId)
      // 获取需要显示的线路集合
      if (this.chooseAssetId === '0') {
        this.$store.commit('set_situation_mapShowAssetIds', [])
      } else {
        const relateIds = this.companyAssetList.find(x => x.keyId === this.chooseAssetId).relateIds
        const mapShowAssetIds = relateIds.split(',').map(x => parseInt(x))
        this.$store.commit('set_situation_mapShowAssetIds', mapShowAssetIds)
      }

      this.chooseTableId = null
      this.$store.commit('set_situation_gbId', null)
      this.$store.commit('set_situation_beignUpdateBottomAndRight', new Date())
      this.loadStationValue()
    },
    loadStationValue () {
      const queryModel = {
        companyId: this.chooseCompanyId,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.chooseAssetId
      }
      this.stationData = []
      this.showArray = []
      computeStationValue(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    rowClassName (row, index) {
      return row.id === this.chooseTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseTableId = (this.chooseTableId === params.id) ? null : params.id
      this.$store.commit('set_situation_gbId', this.chooseTableId)
      this.$store.commit('set_situation_beignUpdateBottomAndRight', new Date())
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.stationData = newArray
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadCompanyList()
      }
    }
  }
}
</script>

<style>
.stationTable .ivu-table-cell{
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
